/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment BTCPipelineFields on MediaPipeline {\n    id\n    activeSlateCode\n    feedType\n    programSource\n    status\n    sourceSystem\n    scheduledStartDateTime\n    streamState\n    resumedFromDateTime\n    eviEnabled\n    eviServerStatus\n    media {\n      id\n      mediaType\n      feedType\n    }\n    alerts {\n      active\n      clearedDateTime\n      createdDateTime\n      id\n      message\n    }\n  }\n": types.BtcPipelineFieldsFragmentDoc,
    "\n  fragment BTCContentFields on Content {\n    callSign\n    feedType\n    gamePk\n    mediaId\n    assets {\n      adStatus\n      assetId\n      cdn\n      provider\n      tier\n      state\n      videoId\n    }\n    mediaState {\n      contentExperience\n      mediaType\n      state\n    }\n  }\n": types.BtcContentFieldsFragmentDoc,
    "\n  mutation switchProgramSource($mediaIds: [String!]!, $programSource: ProgramSource!) {\n    switchProgramSource(mediaIds: $mediaIds, programSource: $programSource) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.SwitchProgramSourceDocument,
    "\n  mutation provisionMedia($mediaIds: [String!]!) {\n    provisionMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n": types.ProvisionMediaDocument,
    "\n  mutation unscheduleMedia($mediaIds: [String!]!) {\n    unscheduleMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n": types.UnscheduleMediaDocument,
    "\n  mutation startMedia($mediaIds: [String!]!) {\n    startMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.StartMediaDocument,
    "\n  mutation stopMedia($mediaIds: [String!]!) {\n    stopMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.StopMediaDocument,
    "\n  mutation archiveMedia($mediaIds: [String!]!) {\n    archiveMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.ArchiveMediaDocument,
    "\n  mutation resolveAlert($mediaId: String!, $alertId: String!) {\n    resolveAlert(mediaId: $mediaId, alertId: $alertId) {\n      mediaId\n      success\n      reason\n    }\n  }\n": types.ResolveAlertDocument,
    "\n  mutation enableFeature($gamePk: Int!, $gameDate: String!, $featureType: FeatureType!) {\n    enableFeature(gamePk: $gamePk, gameDate: $gameDate, featureType: $featureType) {\n      gamePk\n      featureId\n    }\n  }\n": types.EnableFeatureDocument,
    "\n  mutation disableFeature($featureId: Int!) {\n    disableFeature(featureId: $featureId) {\n      featureId\n      success\n    }\n  }\n": types.DisableFeatureDocument,
    "\n  mutation setEviHealthStatus($mediaId: String! $status: PipelineHealthStatus!) {\n    setEviHealthStatus(mediaId: $mediaId, status: $status) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.SetEviHealthStatusDocument,
    "\n  mutation syncMediaContent($gamePk: Int!) {\n   syncMediaContent(gamePk: $gamePk)\n  }\n": types.SyncMediaContentDocument,
    "\n  mutation transitionMediaState($mediaId: String!, $newState: MediaState!) {\n    transitionMediaState(mediaId: $mediaId, newState: $newState) {\n      mediaId\n      success\n      reason\n    }\n  }\n": types.TransitionMediaStateDocument,
    "\n  mutation updateAssetState($mediaId: String!, $assetId: Int!, $assetState: AssetState!) {\n    updateAssetState(mediaId: $mediaId, assetId: $assetId, assetState: $assetState) {\n      mediaId\n      success\n      reason\n    }\n  }\n": types.UpdateAssetStateDocument,
    "\n  query btcSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      features {\n        featureType\n        gameDate\n        gamePk\n        id\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        ...BTCContentFields\n      }\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n": types.BtcScheduleDocument,
    "\n  query btcGamePipelines($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n": types.BtcGamePipelinesDocument,
    "\n  query btcGameContent($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      content {\n        ...BTCContentFields\n      }\n    }\n  }\n": types.BtcGameContentDocument,
    "\n  fragment FeedPolicyFields on FeedPolicy {\n    broadcastTime\n    callSign\n    feedSubType\n    feedType\n    mediaId\n    mediaType\n  }\n": types.FeedPolicyFieldsFragmentDoc,
    "\n  fragment ViewingPolicyFields on ViewingPolicy {\n    id\n    href\n    action\n    actionLabel\n    name\n    audienceHref\n  }\n": types.ViewingPolicyFieldsFragmentDoc,
    "\n  mutation updateAudience($href: String!, $audience: AudienceUpdateInput!) {\n    updateAudience(href: $href, audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n": types.UpdateAudienceDocument,
    "\n  mutation createPolicy($policy: PolicyCreateInput!) {\n    createPolicy(policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n": types.CreatePolicyDocument,
    "\n  mutation updatePolicy($href: String!, $policy: PolicyUpdateInput!) {\n    updatePolicy(href: $href, policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n": types.UpdatePolicyDocument,
    "\n  mutation attachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    attachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n": types.AttachViewingPolicyDocument,
    "\n  mutation detachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    detachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n": types.DetachViewingPolicyDocument,
    "\n  mutation createAudience($audience: AudienceCreateInput!) {\n    createAudience(audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n": types.CreateAudienceDocument,
    "\n  query getMediaFeedPolicies($searchType: FeedPolicySearchType, $searchValue: String) {\n    feedPolicies(searchType: $searchType, searchValue: $searchValue) {\n      ...FeedPolicyFields,\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n": types.GetMediaFeedPoliciesDocument,
    "\n  query audiences {\n    audiences {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n": types.AudiencesDocument,
    "\n  query viewingPolicies {\n    viewingPolicies {\n      ...ViewingPolicyFields\n    }\n  }\n": types.ViewingPoliciesDocument,
    "\n  query audience($href: String!) {\n    audience(href: $href) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n  ": types.AudienceDocument,
    "\n  fragment AuditViewFields on AuditView {\n    traceId\n    createdAt\n    sourceSystem\n    userId\n    userEmail\n    resourceTags {\n      name\n      value\n    }\n    operation\n  }\n": types.AuditViewFieldsFragmentDoc,
    "\n  mutation createLocationOverride($oktaId: String!, $ipAddress: String!, $zipCode: String!, $countryCode: String!) {\n    createLocationOverride(oktaId: $oktaId, ipAddress: $ipAddress, zipCode: $zipCode, countryCode: $countryCode) {\n      status\n    }\n  }\n": types.CreateLocationOverrideDocument,
    "\n  query getActiveLocationOverrides {\n    getActiveLocationOverrides(page: 0, size: 9999999) {\n      currentPage\n      totalPages\n      overrides {\n        oktaId\n        zipCode\n        ipAddress\n        countryCode\n        expiration\n        createdAt\n      }\n    }\n  }\n": types.GetActiveLocationOverridesDocument,
    "\n  query userInfo($email: String!) {\n    userInfo(email: $email) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n": types.UserInfoDocument,
    "\n  query loggerSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n      }\n    }\n  }\n": types.LoggerScheduleDocument,
    "\n  mutation validateLitmusSchema($validationProcess: String!, $gamePk: Int!) {\n    validate(validationProcess: $validationProcess, gamePk: $gamePk) {\n      isValid\n      validationProcess\n      ruleResults {\n        name\n        description\n        result\n      }\n    }\n  }\n": types.ValidateLitmusSchemaDocument,
    "\n  query litmusSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        abbreviation\n      }\n      home {\n        id\n        name\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n    }\n  }\n": types.LitmusScheduleDocument,
    "\n  query validationSchemas {\n    validationSchemas {\n      validationProcess\n      rules {\n        name\n        description\n      }\n    }\n  }\n": types.ValidationSchemasDocument,
    "\n  mutation adjustMilestoneTime($mediaId: String!, $milestoneId: String!, $timestamp: String!) {\n    adjustMilestoneTime(mediaId: $mediaId, milestoneId: $milestoneId, timestamp: $timestamp) {\n      id\n      milestoneType\n      title\n      absoluteTime\n      relativeTime\n      updatedBy\n      keywords {\n        name\n        value\n      }\n    }\n  }\n": types.AdjustMilestoneTimeDocument,
    "\n  mutation deleteMilestone($mediaId: String!, $milestoneId: String!) {\n    deleteMilestone(mediaId: $mediaId, milestoneId: $milestoneId)\n  }\n": types.DeleteMilestoneDocument,
    "\n  mutation insertMilestone($mediaId: String!, $milestoneInput: MilestoneInput!) {\n    insertMilestone(mediaId: $mediaId, milestoneInput: $milestoneInput) {\n      id\n      title\n      milestoneType\n      keywords {\n        name\n        value\n      }\n    }\n  }\n": types.InsertMilestoneDocument,
    "\n  query milestonesSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n      }\n    }\n  }\n": types.MilestonesScheduleDocument,
    "\n  query milestoneSchemas {\n    milestoneSchemas {\n      name\n      displayName\n      description\n      metadataFields {\n        name\n        displayName\n        description\n        type\n      }\n    }\n  }\n": types.MilestoneSchemasDocument,
    "\n  query milestones($mediaId: String!) {\n    milestones(mediaId: $mediaId) {\n      id\n      milestoneType\n      relativeTime\n      absoluteTime\n      title\n      keywords {\n        name\n        value\n      }\n    }\n  }\n": types.MilestonesDocument,
    "\n  mutation endAdBreak($mediaIds: [String]!) {\n    endAdBreak(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.EndAdBreakDocument,
    "\n  mutation insertAdBreak(\n    $mediaIds: [String!]!\n    $durationSeconds: Int!\n    $slateCode: String!\n    $eventType: String\n  ) {\n    insertAdBreak(\n      mediaIds: $mediaIds\n      durationSeconds: $durationSeconds\n      slateCode: $slateCode\n      eventType: $eventType\n    ) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.InsertAdBreakDocument,
    "\n  mutation generatePlaybackUrls($mediaIds: [String!]!) {\n    generatePlaybackUrls(mediaIds: $mediaIds) {\n      playbacks {\n        url\n        cdn\n        token\n        expiration\n        adsEnabled\n        adExperienceType\n        priority\n        adEngineIdentifiers {\n          name\n          value\n        }\n      }\n    }\n  }\n": types.GeneratePlaybackUrlsDocument,
    "\n  mutation routeMedia($mediaIds: [String!]!, $routeType: RouteType) {\n    routeMedia(mediaIds: $mediaIds, routeType: $routeType) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.RouteMediaDocument,
    "\n  mutation setSlate($mediaIds: [String!]!, $slateCode: String!) {\n    setSlate(mediaIds: $mediaIds, slateCode: $slateCode) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.SetSlateDocument,
    "\n  mutation liftSlate($mediaIds: [String!]!) {\n    liftSlate(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n": types.LiftSlateDocument,
    "\n  mutation createExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    createExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n": types.CreateExperienceOverrideDocument,
    "\n  mutation deleteExperienceOverride($overrideId: Int!) {\n    deleteExperienceOverride(overrideId: $overrideId)\n  }\n": types.DeleteExperienceOverrideDocument,
    "\n  mutation updateExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    updateExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n": types.UpdateExperienceOverrideDocument,
    "\n  mutation assignTargets($targetChanges: [TargetChangeInput!]!) {\n    assignTargets(targetChanges: $targetChanges) {\n      success\n      reason\n      channelId\n      target {\n        id\n        type\n      }\n    }\n  }\n": types.AssignTargetsDocument,
    "\n  query experienceOverrides {\n    experienceOverrides {\n      id\n      experience\n      features\n      matchParams\n    }\n  }\n": types.ExperienceOverridesDocument,
    "\n  fragment PipelineConfigFields on PipelineConfigInfo {\n    activeSource\n    altaChannelUrl\n    backhaulDecoderHost\n    backhaulDecoderServiceLabel\n    cam4DecoderHost\n    cam4DecoderServiceLabel\n    cam4Src\n    eviBackhaulSrc\n    eviProgramSwitchSrc\n    eviSdpUrlAudio\n    eviSdpUrlVideo\n    eviSwitchDst\n    fronthaulDecoderHost\n    fronthaulDecoderServiceLabel\n    id\n    lexiInstanceUrl\n    partnerLockEnabled\n    partnerLockExclusions\n    pd01Dst\n    pd02Dst\n    pd03Dst\n    postGameSrc\n    preGameSrc\n    primaryBackhaulSrc\n    primaryFronthaulSrc\n    programSwitchDst\n    programSwitchSrc\n    sdpUrlAncillary\n    sdpUrlAudio\n    sdpUrlVideo\n    teamId\n    videonStreamUrl\n  }\n": types.PipelineConfigFieldsFragmentDoc,
    "\n  mutation updatePipelineConfig($teamId: Int!, $pipelineConfig: PipelineConfig!) {\n    updatePipelineConfig(teamId: $teamId, pipelineConfig: $pipelineConfig) {\n      ...PipelineConfigFields\n    }\n  }\n": types.UpdatePipelineConfigDocument,
    "\n  query getPipelineConfigs {\n    pipelineConfigs {\n      ...PipelineConfigFields\n    }\n  }\n": types.GetPipelineConfigsDocument,
    "mutation updatePipelineInputConfig($venueId: Int!, $feedType: MediaFeedType!, $pipelineInputConfig: PipelineInputConfig) {\n    updatePipelineInputConfig(venueId: $venueId, feedType: $feedType, pipelineInputConfig: $pipelineInputConfig) {\n      venueId\n      feedTypeCode\n      id\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }": types.UpdatePipelineInputConfigDocument,
    "\n  query pipelineInputConfigs($venueId: Int!) {\n    pipelineInputConfigs(venueId: $venueId) {\n      id\n      venueId\n      feedTypeCode\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }\n": types.PipelineInputConfigsDocument,
    "\n  query adEventTypes($sport: Sport = MLB) {\n    adEventTypes(sport: $sport) {\n      label\n      value\n    }\n  }\n": types.AdEventTypesDocument,
    "\n  query game($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        mediaId\n        mediaState {\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n": types.GameDocument,
    "\n  query schedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        gamePk\n        mediaId\n        mediaState {\n          contentExperience\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n": types.ScheduleDocument,
    "\n  query slates($sport: Sport = MLB, $type: SlateType) {\n    slates(sport: $sport, type: $type) {\n      id\n      code\n      type\n      description\n      thumbnailUri\n      sourceSystem\n    }\n  }\n": types.SlatesDocument,
    "\n  query getAuditByGame($gamePk: Int!) {\n    getAuditByGame(gamePk: $gamePk) {\n      ...AuditViewFields\n    }\n  }\n": types.GetAuditByGameDocument,
    "\n  query getAuditByMedia($mediaId: String!){\n    getAuditByMedia(mediaId: $mediaId) {\n      ...AuditViewFields\n    }\n  }\n": types.GetAuditByMediaDocument,
    "\n  query getAuditByUser($email: String!){\n    getAuditByUser(email: $email) {\n      ...AuditViewFields\n    }\n  }\n": types.GetAuditByUserDocument,
    "\n  query getAllChannels {\n    getAllChannels {\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n": types.GetAllChannelsDocument,
    "\n  query getChannel($name: String!) {\n    getChannel(name: $name) {\n      id\n      name\n      targets {\n        id\n        type\n      }\n    }\n  }\n": types.GetChannelDocument,
    "\n  query getAllTargets {\n    getAllTargets {\n      name\n      targets {\n        id\n        name\n        status\n        type\n        channelId\n      }\n    }\n  }\n": types.GetAllTargetsDocument,
    "\n  query getTarget($name: String!) {\n    getTarget(name: $name) {\n      id\n      name\n      status\n      type\n      channelId\n    }\n  }\n": types.GetTargetDocument,
    "\n  subscription mediaUpdates($mediaIds: [String!]!) {\n    mediaUpdates(mediaIds: $mediaIds) {\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        eviEnabled\n        eviServerStatus\n        media {\n          id\n          mediaType\n          feedType\n        }\n        alerts {\n          active\n          clearedDateTime\n          createdDateTime\n          id\n          message\n        }\n      }\n    }\n  }\n": types.MediaUpdatesDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BTCPipelineFields on MediaPipeline {\n    id\n    activeSlateCode\n    feedType\n    programSource\n    status\n    sourceSystem\n    scheduledStartDateTime\n    streamState\n    resumedFromDateTime\n    eviEnabled\n    eviServerStatus\n    media {\n      id\n      mediaType\n      feedType\n    }\n    alerts {\n      active\n      clearedDateTime\n      createdDateTime\n      id\n      message\n    }\n  }\n"): (typeof documents)["\n  fragment BTCPipelineFields on MediaPipeline {\n    id\n    activeSlateCode\n    feedType\n    programSource\n    status\n    sourceSystem\n    scheduledStartDateTime\n    streamState\n    resumedFromDateTime\n    eviEnabled\n    eviServerStatus\n    media {\n      id\n      mediaType\n      feedType\n    }\n    alerts {\n      active\n      clearedDateTime\n      createdDateTime\n      id\n      message\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment BTCContentFields on Content {\n    callSign\n    feedType\n    gamePk\n    mediaId\n    assets {\n      adStatus\n      assetId\n      cdn\n      provider\n      tier\n      state\n      videoId\n    }\n    mediaState {\n      contentExperience\n      mediaType\n      state\n    }\n  }\n"): (typeof documents)["\n  fragment BTCContentFields on Content {\n    callSign\n    feedType\n    gamePk\n    mediaId\n    assets {\n      adStatus\n      assetId\n      cdn\n      provider\n      tier\n      state\n      videoId\n    }\n    mediaState {\n      contentExperience\n      mediaType\n      state\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation switchProgramSource($mediaIds: [String!]!, $programSource: ProgramSource!) {\n    switchProgramSource(mediaIds: $mediaIds, programSource: $programSource) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation switchProgramSource($mediaIds: [String!]!, $programSource: ProgramSource!) {\n    switchProgramSource(mediaIds: $mediaIds, programSource: $programSource) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation provisionMedia($mediaIds: [String!]!) {\n    provisionMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n"): (typeof documents)["\n  mutation provisionMedia($mediaIds: [String!]!) {\n    provisionMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation unscheduleMedia($mediaIds: [String!]!) {\n    unscheduleMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n"): (typeof documents)["\n  mutation unscheduleMedia($mediaIds: [String!]!) {\n    unscheduleMedia(mediaIds: $mediaIds) {\n      mediaId\n      success\n      reason\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation startMedia($mediaIds: [String!]!) {\n    startMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation startMedia($mediaIds: [String!]!) {\n    startMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation stopMedia($mediaIds: [String!]!) {\n    stopMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation stopMedia($mediaIds: [String!]!) {\n    stopMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation archiveMedia($mediaIds: [String!]!) {\n    archiveMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation archiveMedia($mediaIds: [String!]!) {\n    archiveMedia(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation resolveAlert($mediaId: String!, $alertId: String!) {\n    resolveAlert(mediaId: $mediaId, alertId: $alertId) {\n      mediaId\n      success\n      reason\n    }\n  }\n"): (typeof documents)["\n  mutation resolveAlert($mediaId: String!, $alertId: String!) {\n    resolveAlert(mediaId: $mediaId, alertId: $alertId) {\n      mediaId\n      success\n      reason\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation enableFeature($gamePk: Int!, $gameDate: String!, $featureType: FeatureType!) {\n    enableFeature(gamePk: $gamePk, gameDate: $gameDate, featureType: $featureType) {\n      gamePk\n      featureId\n    }\n  }\n"): (typeof documents)["\n  mutation enableFeature($gamePk: Int!, $gameDate: String!, $featureType: FeatureType!) {\n    enableFeature(gamePk: $gamePk, gameDate: $gameDate, featureType: $featureType) {\n      gamePk\n      featureId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation disableFeature($featureId: Int!) {\n    disableFeature(featureId: $featureId) {\n      featureId\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation disableFeature($featureId: Int!) {\n    disableFeature(featureId: $featureId) {\n      featureId\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation setEviHealthStatus($mediaId: String! $status: PipelineHealthStatus!) {\n    setEviHealthStatus(mediaId: $mediaId, status: $status) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation setEviHealthStatus($mediaId: String! $status: PipelineHealthStatus!) {\n    setEviHealthStatus(mediaId: $mediaId, status: $status) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation syncMediaContent($gamePk: Int!) {\n   syncMediaContent(gamePk: $gamePk)\n  }\n"): (typeof documents)["\n  mutation syncMediaContent($gamePk: Int!) {\n   syncMediaContent(gamePk: $gamePk)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation transitionMediaState($mediaId: String!, $newState: MediaState!) {\n    transitionMediaState(mediaId: $mediaId, newState: $newState) {\n      mediaId\n      success\n      reason\n    }\n  }\n"): (typeof documents)["\n  mutation transitionMediaState($mediaId: String!, $newState: MediaState!) {\n    transitionMediaState(mediaId: $mediaId, newState: $newState) {\n      mediaId\n      success\n      reason\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateAssetState($mediaId: String!, $assetId: Int!, $assetState: AssetState!) {\n    updateAssetState(mediaId: $mediaId, assetId: $assetId, assetState: $assetState) {\n      mediaId\n      success\n      reason\n    }\n  }\n"): (typeof documents)["\n  mutation updateAssetState($mediaId: String!, $assetId: Int!, $assetState: AssetState!) {\n    updateAssetState(mediaId: $mediaId, assetId: $assetId, assetState: $assetState) {\n      mediaId\n      success\n      reason\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query btcSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      features {\n        featureType\n        gameDate\n        gamePk\n        id\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        ...BTCContentFields\n      }\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query btcSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      features {\n        featureType\n        gameDate\n        gamePk\n        id\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        ...BTCContentFields\n      }\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query btcGamePipelines($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query btcGamePipelines($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      pipelines {\n        ...BTCPipelineFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query btcGameContent($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      content {\n        ...BTCContentFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query btcGameContent($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      gamePk\n      content {\n        ...BTCContentFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment FeedPolicyFields on FeedPolicy {\n    broadcastTime\n    callSign\n    feedSubType\n    feedType\n    mediaId\n    mediaType\n  }\n"): (typeof documents)["\n  fragment FeedPolicyFields on FeedPolicy {\n    broadcastTime\n    callSign\n    feedSubType\n    feedType\n    mediaId\n    mediaType\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment ViewingPolicyFields on ViewingPolicy {\n    id\n    href\n    action\n    actionLabel\n    name\n    audienceHref\n  }\n"): (typeof documents)["\n  fragment ViewingPolicyFields on ViewingPolicy {\n    id\n    href\n    action\n    actionLabel\n    name\n    audienceHref\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateAudience($href: String!, $audience: AudienceUpdateInput!) {\n    updateAudience(href: $href, audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"): (typeof documents)["\n  mutation updateAudience($href: String!, $audience: AudienceUpdateInput!) {\n    updateAudience(href: $href, audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createPolicy($policy: PolicyCreateInput!) {\n    createPolicy(policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n"): (typeof documents)["\n  mutation createPolicy($policy: PolicyCreateInput!) {\n    createPolicy(policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePolicy($href: String!, $policy: PolicyUpdateInput!) {\n    updatePolicy(href: $href, policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n"): (typeof documents)["\n  mutation updatePolicy($href: String!, $policy: PolicyUpdateInput!) {\n    updatePolicy(href: $href, policy: $policy) {\n      ...ViewingPolicyFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation attachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    attachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation attachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    attachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation detachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    detachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation detachViewingPolicy($mediaId: String!, $policyHref: String!) {\n    detachViewingPolicy(mediaId: $mediaId, policyHref: $policyHref) {\n      ...FeedPolicyFields\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createAudience($audience: AudienceCreateInput!) {\n    createAudience(audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"): (typeof documents)["\n  mutation createAudience($audience: AudienceCreateInput!) {\n    createAudience(audience: $audience) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getMediaFeedPolicies($searchType: FeedPolicySearchType, $searchValue: String) {\n    feedPolicies(searchType: $searchType, searchValue: $searchValue) {\n      ...FeedPolicyFields,\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"): (typeof documents)["\n  query getMediaFeedPolicies($searchType: FeedPolicySearchType, $searchValue: String) {\n    feedPolicies(searchType: $searchType, searchValue: $searchValue) {\n      ...FeedPolicyFields,\n      viewingPolicies {\n        ...ViewingPolicyFields\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query audiences {\n    audiences {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"): (typeof documents)["\n  query audiences {\n    audiences {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query viewingPolicies {\n    viewingPolicies {\n      ...ViewingPolicyFields\n    }\n  }\n"): (typeof documents)["\n  query viewingPolicies {\n    viewingPolicies {\n      ...ViewingPolicyFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query audience($href: String!) {\n    audience(href: $href) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n  "): (typeof documents)["\n  query audience($href: String!) {\n    audience(href: $href) {\n      href\n      authenticated\n      match\n      type\n      details\n    }\n  }\n  "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment AuditViewFields on AuditView {\n    traceId\n    createdAt\n    sourceSystem\n    userId\n    userEmail\n    resourceTags {\n      name\n      value\n    }\n    operation\n  }\n"): (typeof documents)["\n  fragment AuditViewFields on AuditView {\n    traceId\n    createdAt\n    sourceSystem\n    userId\n    userEmail\n    resourceTags {\n      name\n      value\n    }\n    operation\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createLocationOverride($oktaId: String!, $ipAddress: String!, $zipCode: String!, $countryCode: String!) {\n    createLocationOverride(oktaId: $oktaId, ipAddress: $ipAddress, zipCode: $zipCode, countryCode: $countryCode) {\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation createLocationOverride($oktaId: String!, $ipAddress: String!, $zipCode: String!, $countryCode: String!) {\n    createLocationOverride(oktaId: $oktaId, ipAddress: $ipAddress, zipCode: $zipCode, countryCode: $countryCode) {\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getActiveLocationOverrides {\n    getActiveLocationOverrides(page: 0, size: 9999999) {\n      currentPage\n      totalPages\n      overrides {\n        oktaId\n        zipCode\n        ipAddress\n        countryCode\n        expiration\n        createdAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query getActiveLocationOverrides {\n    getActiveLocationOverrides(page: 0, size: 9999999) {\n      currentPage\n      totalPages\n      overrides {\n        oktaId\n        zipCode\n        ipAddress\n        countryCode\n        expiration\n        createdAt\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userInfo($email: String!) {\n    userInfo(email: $email) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  query userInfo($email: String!) {\n    userInfo(email: $email) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query loggerSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  query loggerSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation validateLitmusSchema($validationProcess: String!, $gamePk: Int!) {\n    validate(validationProcess: $validationProcess, gamePk: $gamePk) {\n      isValid\n      validationProcess\n      ruleResults {\n        name\n        description\n        result\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation validateLitmusSchema($validationProcess: String!, $gamePk: Int!) {\n    validate(validationProcess: $validationProcess, gamePk: $gamePk) {\n      isValid\n      validationProcess\n      ruleResults {\n        name\n        description\n        result\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query litmusSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        abbreviation\n      }\n      home {\n        id\n        name\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n    }\n  }\n"): (typeof documents)["\n  query litmusSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        abbreviation\n      }\n      home {\n        id\n        name\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query validationSchemas {\n    validationSchemas {\n      validationProcess\n      rules {\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  query validationSchemas {\n    validationSchemas {\n      validationProcess\n      rules {\n        name\n        description\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation adjustMilestoneTime($mediaId: String!, $milestoneId: String!, $timestamp: String!) {\n    adjustMilestoneTime(mediaId: $mediaId, milestoneId: $milestoneId, timestamp: $timestamp) {\n      id\n      milestoneType\n      title\n      absoluteTime\n      relativeTime\n      updatedBy\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation adjustMilestoneTime($mediaId: String!, $milestoneId: String!, $timestamp: String!) {\n    adjustMilestoneTime(mediaId: $mediaId, milestoneId: $milestoneId, timestamp: $timestamp) {\n      id\n      milestoneType\n      title\n      absoluteTime\n      relativeTime\n      updatedBy\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteMilestone($mediaId: String!, $milestoneId: String!) {\n    deleteMilestone(mediaId: $mediaId, milestoneId: $milestoneId)\n  }\n"): (typeof documents)["\n  mutation deleteMilestone($mediaId: String!, $milestoneId: String!) {\n    deleteMilestone(mediaId: $mediaId, milestoneId: $milestoneId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation insertMilestone($mediaId: String!, $milestoneInput: MilestoneInput!) {\n    insertMilestone(mediaId: $mediaId, milestoneInput: $milestoneInput) {\n      id\n      title\n      milestoneType\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation insertMilestone($mediaId: String!, $milestoneInput: MilestoneInput!) {\n    insertMilestone(mediaId: $mediaId, milestoneInput: $milestoneInput) {\n      id\n      title\n      milestoneType\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query milestonesSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query milestonesSchedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      gameDate\n      gamePk\n      status\n      pipelines {\n        id\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query milestoneSchemas {\n    milestoneSchemas {\n      name\n      displayName\n      description\n      metadataFields {\n        name\n        displayName\n        description\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query milestoneSchemas {\n    milestoneSchemas {\n      name\n      displayName\n      description\n      metadataFields {\n        name\n        displayName\n        description\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query milestones($mediaId: String!) {\n    milestones(mediaId: $mediaId) {\n      id\n      milestoneType\n      relativeTime\n      absoluteTime\n      title\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"): (typeof documents)["\n  query milestones($mediaId: String!) {\n    milestones(mediaId: $mediaId) {\n      id\n      milestoneType\n      relativeTime\n      absoluteTime\n      title\n      keywords {\n        name\n        value\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation endAdBreak($mediaIds: [String]!) {\n    endAdBreak(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation endAdBreak($mediaIds: [String]!) {\n    endAdBreak(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation insertAdBreak(\n    $mediaIds: [String!]!\n    $durationSeconds: Int!\n    $slateCode: String!\n    $eventType: String\n  ) {\n    insertAdBreak(\n      mediaIds: $mediaIds\n      durationSeconds: $durationSeconds\n      slateCode: $slateCode\n      eventType: $eventType\n    ) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation insertAdBreak(\n    $mediaIds: [String!]!\n    $durationSeconds: Int!\n    $slateCode: String!\n    $eventType: String\n  ) {\n    insertAdBreak(\n      mediaIds: $mediaIds\n      durationSeconds: $durationSeconds\n      slateCode: $slateCode\n      eventType: $eventType\n    ) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation generatePlaybackUrls($mediaIds: [String!]!) {\n    generatePlaybackUrls(mediaIds: $mediaIds) {\n      playbacks {\n        url\n        cdn\n        token\n        expiration\n        adsEnabled\n        adExperienceType\n        priority\n        adEngineIdentifiers {\n          name\n          value\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation generatePlaybackUrls($mediaIds: [String!]!) {\n    generatePlaybackUrls(mediaIds: $mediaIds) {\n      playbacks {\n        url\n        cdn\n        token\n        expiration\n        adsEnabled\n        adExperienceType\n        priority\n        adEngineIdentifiers {\n          name\n          value\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation routeMedia($mediaIds: [String!]!, $routeType: RouteType) {\n    routeMedia(mediaIds: $mediaIds, routeType: $routeType) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation routeMedia($mediaIds: [String!]!, $routeType: RouteType) {\n    routeMedia(mediaIds: $mediaIds, routeType: $routeType) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation setSlate($mediaIds: [String!]!, $slateCode: String!) {\n    setSlate(mediaIds: $mediaIds, slateCode: $slateCode) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation setSlate($mediaIds: [String!]!, $slateCode: String!) {\n    setSlate(mediaIds: $mediaIds, slateCode: $slateCode) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation liftSlate($mediaIds: [String!]!) {\n    liftSlate(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation liftSlate($mediaIds: [String!]!) {\n    liftSlate(mediaIds: $mediaIds) {\n      mediaId\n      reason\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    createExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n"): (typeof documents)["\n  mutation createExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    createExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation deleteExperienceOverride($overrideId: Int!) {\n    deleteExperienceOverride(overrideId: $overrideId)\n  }\n"): (typeof documents)["\n  mutation deleteExperienceOverride($overrideId: Int!) {\n    deleteExperienceOverride(overrideId: $overrideId)\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    updateExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n"): (typeof documents)["\n  mutation updateExperienceOverride(\n    $experience: String!\n    $features: [String!]!\n    $id: Int!\n    $matchParams: JSON!\n  ) {\n    updateExperienceOverride(\n      experience: $experience\n      features: $features\n      id: $id\n      matchParams: $matchParams\n    )\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation assignTargets($targetChanges: [TargetChangeInput!]!) {\n    assignTargets(targetChanges: $targetChanges) {\n      success\n      reason\n      channelId\n      target {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation assignTargets($targetChanges: [TargetChangeInput!]!) {\n    assignTargets(targetChanges: $targetChanges) {\n      success\n      reason\n      channelId\n      target {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query experienceOverrides {\n    experienceOverrides {\n      id\n      experience\n      features\n      matchParams\n    }\n  }\n"): (typeof documents)["\n  query experienceOverrides {\n    experienceOverrides {\n      id\n      experience\n      features\n      matchParams\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  fragment PipelineConfigFields on PipelineConfigInfo {\n    activeSource\n    altaChannelUrl\n    backhaulDecoderHost\n    backhaulDecoderServiceLabel\n    cam4DecoderHost\n    cam4DecoderServiceLabel\n    cam4Src\n    eviBackhaulSrc\n    eviProgramSwitchSrc\n    eviSdpUrlAudio\n    eviSdpUrlVideo\n    eviSwitchDst\n    fronthaulDecoderHost\n    fronthaulDecoderServiceLabel\n    id\n    lexiInstanceUrl\n    partnerLockEnabled\n    partnerLockExclusions\n    pd01Dst\n    pd02Dst\n    pd03Dst\n    postGameSrc\n    preGameSrc\n    primaryBackhaulSrc\n    primaryFronthaulSrc\n    programSwitchDst\n    programSwitchSrc\n    sdpUrlAncillary\n    sdpUrlAudio\n    sdpUrlVideo\n    teamId\n    videonStreamUrl\n  }\n"): (typeof documents)["\n  fragment PipelineConfigFields on PipelineConfigInfo {\n    activeSource\n    altaChannelUrl\n    backhaulDecoderHost\n    backhaulDecoderServiceLabel\n    cam4DecoderHost\n    cam4DecoderServiceLabel\n    cam4Src\n    eviBackhaulSrc\n    eviProgramSwitchSrc\n    eviSdpUrlAudio\n    eviSdpUrlVideo\n    eviSwitchDst\n    fronthaulDecoderHost\n    fronthaulDecoderServiceLabel\n    id\n    lexiInstanceUrl\n    partnerLockEnabled\n    partnerLockExclusions\n    pd01Dst\n    pd02Dst\n    pd03Dst\n    postGameSrc\n    preGameSrc\n    primaryBackhaulSrc\n    primaryFronthaulSrc\n    programSwitchDst\n    programSwitchSrc\n    sdpUrlAncillary\n    sdpUrlAudio\n    sdpUrlVideo\n    teamId\n    videonStreamUrl\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updatePipelineConfig($teamId: Int!, $pipelineConfig: PipelineConfig!) {\n    updatePipelineConfig(teamId: $teamId, pipelineConfig: $pipelineConfig) {\n      ...PipelineConfigFields\n    }\n  }\n"): (typeof documents)["\n  mutation updatePipelineConfig($teamId: Int!, $pipelineConfig: PipelineConfig!) {\n    updatePipelineConfig(teamId: $teamId, pipelineConfig: $pipelineConfig) {\n      ...PipelineConfigFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getPipelineConfigs {\n    pipelineConfigs {\n      ...PipelineConfigFields\n    }\n  }\n"): (typeof documents)["\n  query getPipelineConfigs {\n    pipelineConfigs {\n      ...PipelineConfigFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updatePipelineInputConfig($venueId: Int!, $feedType: MediaFeedType!, $pipelineInputConfig: PipelineInputConfig) {\n    updatePipelineInputConfig(venueId: $venueId, feedType: $feedType, pipelineInputConfig: $pipelineInputConfig) {\n      venueId\n      feedTypeCode\n      id\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }"): (typeof documents)["mutation updatePipelineInputConfig($venueId: Int!, $feedType: MediaFeedType!, $pipelineInputConfig: PipelineInputConfig) {\n    updatePipelineInputConfig(venueId: $venueId, feedType: $feedType, pipelineInputConfig: $pipelineInputConfig) {\n      venueId\n      feedTypeCode\n      id\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query pipelineInputConfigs($venueId: Int!) {\n    pipelineInputConfigs(venueId: $venueId) {\n      id\n      venueId\n      feedTypeCode\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }\n"): (typeof documents)["\n  query pipelineInputConfigs($venueId: Int!) {\n    pipelineInputConfigs(venueId: $venueId) {\n      id\n      venueId\n      feedTypeCode\n      inputLabel\n      cam4InputLabel\n      category\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query adEventTypes($sport: Sport = MLB) {\n    adEventTypes(sport: $sport) {\n      label\n      value\n    }\n  }\n"): (typeof documents)["\n  query adEventTypes($sport: Sport = MLB) {\n    adEventTypes(sport: $sport) {\n      label\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query game($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        mediaId\n        mediaState {\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query game($gamePk: Int!, $scheduleDate: String) {\n    game(gamePk: $gamePk, scheduleDate: $scheduleDate) {\n      away {\n        id\n        abbreviation\n      }\n      home {\n        id\n        abbreviation\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        mediaId\n        mediaState {\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query schedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        gamePk\n        mediaId\n        mediaState {\n          contentExperience\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query schedule($date: String = \"\", $sports: [Sport] = [MLB]) {\n    schedule(date: $date, sports: $sports) {\n      away {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      home {\n        id\n        name\n        shortName\n        abbreviation\n      }\n      venue {\n        id\n        name\n      }\n      feeds {\n        feedType\n        postGameShow\n        preGameShow\n      }\n      gameDate\n      gamePk\n      status\n      content {\n        callSign\n        feedType\n        gamePk\n        mediaId\n        mediaState {\n          contentExperience\n          mediaType\n          state\n        }\n      }\n      pipelines {\n        id\n        feedType\n        programSource\n        status\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        media {\n          id\n          mediaType\n          feedType\n        }\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query slates($sport: Sport = MLB, $type: SlateType) {\n    slates(sport: $sport, type: $type) {\n      id\n      code\n      type\n      description\n      thumbnailUri\n      sourceSystem\n    }\n  }\n"): (typeof documents)["\n  query slates($sport: Sport = MLB, $type: SlateType) {\n    slates(sport: $sport, type: $type) {\n      id\n      code\n      type\n      description\n      thumbnailUri\n      sourceSystem\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAuditByGame($gamePk: Int!) {\n    getAuditByGame(gamePk: $gamePk) {\n      ...AuditViewFields\n    }\n  }\n"): (typeof documents)["\n  query getAuditByGame($gamePk: Int!) {\n    getAuditByGame(gamePk: $gamePk) {\n      ...AuditViewFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAuditByMedia($mediaId: String!){\n    getAuditByMedia(mediaId: $mediaId) {\n      ...AuditViewFields\n    }\n  }\n"): (typeof documents)["\n  query getAuditByMedia($mediaId: String!){\n    getAuditByMedia(mediaId: $mediaId) {\n      ...AuditViewFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAuditByUser($email: String!){\n    getAuditByUser(email: $email) {\n      ...AuditViewFields\n    }\n  }\n"): (typeof documents)["\n  query getAuditByUser($email: String!){\n    getAuditByUser(email: $email) {\n      ...AuditViewFields\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllChannels {\n    getAllChannels {\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllChannels {\n    getAllChannels {\n      name\n      channels {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getChannel($name: String!) {\n    getChannel(name: $name) {\n      id\n      name\n      targets {\n        id\n        type\n      }\n    }\n  }\n"): (typeof documents)["\n  query getChannel($name: String!) {\n    getChannel(name: $name) {\n      id\n      name\n      targets {\n        id\n        type\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getAllTargets {\n    getAllTargets {\n      name\n      targets {\n        id\n        name\n        status\n        type\n        channelId\n      }\n    }\n  }\n"): (typeof documents)["\n  query getAllTargets {\n    getAllTargets {\n      name\n      targets {\n        id\n        name\n        status\n        type\n        channelId\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getTarget($name: String!) {\n    getTarget(name: $name) {\n      id\n      name\n      status\n      type\n      channelId\n    }\n  }\n"): (typeof documents)["\n  query getTarget($name: String!) {\n    getTarget(name: $name) {\n      id\n      name\n      status\n      type\n      channelId\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  subscription mediaUpdates($mediaIds: [String!]!) {\n    mediaUpdates(mediaIds: $mediaIds) {\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        eviEnabled\n        eviServerStatus\n        media {\n          id\n          mediaType\n          feedType\n        }\n        alerts {\n          active\n          clearedDateTime\n          createdDateTime\n          id\n          message\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription mediaUpdates($mediaIds: [String!]!) {\n    mediaUpdates(mediaIds: $mediaIds) {\n      pipelines {\n        id\n        activeSlateCode\n        feedType\n        programSource\n        status\n        sourceSystem\n        scheduledStartDateTime\n        streamState\n        resumedFromDateTime\n        eviEnabled\n        eviServerStatus\n        media {\n          id\n          mediaType\n          feedType\n        }\n        alerts {\n          active\n          clearedDateTime\n          createdDateTime\n          id\n          message\n        }\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;